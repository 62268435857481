.collapsibleSubcontainer {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}
.instructionsContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
}
.instructionsContainer p {
  padding: 0;
  margin: 0;
}
.dataContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-top: 5px;
  width: 100%;
}
.colTitle {
  text-decoration: underline;
  padding-bottom: 3px;
}
.dataSubcol {
  width: 50%;
  padding-left: 10px;
  margin-bottom: 3px;
  text-align: left;
}
.typeSubcol {
  width: 50%;
  padding-left: 10px;
  margin-bottom: 3px;
  text-align: left;
}
.nameSubcol {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 50%;
  padding-left: 10px;
  margin-bottom: 3px;
  text-align: left;
}
.notification {
  margin-top: 30px;
  margin-left: 80px;
  letter-spacing: 0.3px;
}
.warning {
  border: 1px solid rgba(249, 40, 15, 1);
  background: rgba(249, 40, 15, 0.5);

}
.success {
  border: 1px solid rgba(255,255,255,0.4);
}
.notification :global(.ant-notification-notice-close) * {
  color: #fff;
}
.button {
  background-color: #f06;
  width: 80px;
  height: 30px;
  margin: 16px;
  border: 1px solid #fff;
  float: right;
}
.input {
  width: 100%;
  margin-right: 16px;
  height: 25px;
  font-size: 14px;
}
.manualSourceTitle {
  margin-top: 15px;
  font-size: 16px;
  padding-left: 10px;
  font-weight: 500;
}
.sourceInstructions {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  padding-left: 10px;
}
.refreshButton {
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0;
}
.refreshButton:hover,
.refreshButton:active {
  background-color: #15181c;
  fill: #f06;
  color: #f06;
}
.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
