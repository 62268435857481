.categoryContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  overflow-y: hidden;
}

.categories {
  width: calc(100% - 39px);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.showMoreButton {
  cursor: pointer;
}

.containerWidth {
  width: 200px;
}
