.confirmModal :global(.ant-modal-content) {
  z-index: 100;
  width: 500px;
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
}

.confirmModal :global(.ant-modal-header) {
  border-bottom: 2px solid #f06;
}

.confirmModal :global(.ant-modal-content) * {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
}

.confirmModal :global(.ant-modal-footer) {
  text-align: center;
  padding-top: 0;
  border: none;
}

.addToFinalListTextarea {
  font-size: 15px;
}
