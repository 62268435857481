.optionSelectSources .ant-select-selection,
.optionSelect .ant-select-selection {
  background-color: #747474;
  padding-bottom: 0;
  margin: 0;
}

.optionSelect .ant-select-selection {
  width: 200px;
  height: 28px;
  padding: 0;
}
.optionSelectSources .ant-select-selection {
  width: 160px;
  height: 25px;
  padding: 0;
}
.ant-select-dropdown {
  background-color: #747474;
}
.optionSelectSources .ant-select-dropdown {
  background-color: #747474;
  font-size: 15px;
  font-weight: 300;
}
.optionSelectSources .ant-select-selection-selected-value,
.optionSelect .ant-select-selection-selected-value {
  line-height: 20px;
  font-weight: 300;
}
.optionSelectSources .ant-select-selection-selected-value {
  font-size: 15px;
  letter-spacing: 0;
  padding-top: 1px;
}
.optionSelectSources .ant-select-selection__rendered {
  display: flex;
  height: 22px;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.2px;
  align-content: center;
  margin-left: 5px;
  margin-right: 5px;
}
.optionSelect .ant-select-selection-selected-value {
  padding-top: 4.5px;
  font-size: 18px;
}
.anticon {
  font-size: 18px;
}
.optionSelect .anticon,
.optionSelectSources .anticon {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.srcDropdown .ant-select-dropdown-menu-item {
  font-size: 15px;
  font-weight: 400;
}
.normalDropdown .ant-select-dropdown-menu-item {
  font-size: 18px;
  font-weight: 400;
}
