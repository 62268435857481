.thumbnail {
  position: relative;
  width: 121px;
  height: 121px;
  overflow: hidden;
  border-bottom: 1px solid #E4E4E4;
  border-left: 1px solid #E4E4E4;
}
.photo {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}
.photoNotLoaded {
  visibility: hidden;
}
.loadingFailedCaption {
  display: block;
  text-transform: uppercase;
  position: absolute;
  top: 45%;
  width: 100%;
  transform: translateY(-50%);
  max-width: 100%;
  color: #4f4f4f;
  white-space: break-spaces;
  text-align: center;
  font-weight: 400;
  padding: 10px;
}
.displayCountOverlay {
  display: none;
}
.photoContainer:hover .displayCountOverlay {
  display: flex;
}
