/*
to display add to parent component CSS file to Overlay parent tag:
   .parent {position: relative;}
   .parent:hover [class="engagement"] {display: flex;}
*/

.countPhotoOverlay {
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: rgba(21, 24, 28, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.counts {
  display: flex;
  width: 52px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: #fff;
  padding: 2px 0;
}

.icon {
  margin-right: 10px;
  box-sizing: border-box;
  height: 17px;
  cursor: pointer;
}
