.mainContainer {
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1300;
}
.overlayContainer {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1400;
}
.errorAlert {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: fixed;
  top: 34%;
  left: 37%;
  z-index: 1500;
}
.errorMessage {
  padding: 10px 30px 0 20px;
  margin-bottom: 10px;
}
.errorMessageText {
  font-size: 15px;
  letter-spacing: 0.2px;
}
.message {
  font-size: 17px;
  text-decoration: underline;
}
.errorMessage pre {
  padding-top: 10px;
}
.reloadButton {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 17px;
}
.email {
  margin-top: 15px;
  color: #60C0F9;
  font-weight: 600;
  font-size: 17px;
  text-decoration: underline;
}
.email:hover {
  color: #60C0F9;
}
:global(.ant-alert.ant-alert-error.ant-alert-no-icon).errorAlert {
  position: absolute;
  top: 30%;
  left: 30%;
  z-index: 1500;
  margin-bottom: 20px;
}
