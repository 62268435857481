.container {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.main,
.main h1 {
  text-align: center;
  color: #fff;
  padding: 20px 0 40px 0;
  width: 500px;
}
