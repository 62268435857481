.breadcrumb :global(.ant-breadcrumb) a,
.breadcrumb :global(.ant-breadcrumb-separator) {
  font-weight: 400;
  filter: brightness(0) invert(65%);
}

.icon svg {
  padding: 1px 4px 0 0;
}

.breadcrumb :global(.ant-breadcrumb) a:hover,
.breadcrumb :global(.ant-breadcrumb) span:last-child a {
  text-decoration: none;
  filter: none;
  color: #f06;
  font-weight: 550;
}
