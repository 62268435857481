.collapsibleSubcontainer {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}
.dataContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-top: 5px;
  width: 100%;
}
.dataName {
  width: 47%;
  padding-left: 10px;
}
.dataCol {
  width: 53%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.colTitle {
  text-decoration: underline;
  padding-bottom: 3px;
}
.dataSubcol {
  width: 50%;
  padding-left: 15px;
  margin-bottom: 3px;
  text-align: left;
}
.dataItemColor {
  color: rgba(255,255,255,0.7);
}
