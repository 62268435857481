:global(.ant-modal).container {
  min-width: 520px;
  height: 500px;
}

.yesButton {
  border-color: #04cd98;
  width: 60px;
  height: 25px;
}

.noButton {
  width: 60px;
  height: 25px;
}

.container :global(.ant-modal-body) .content {
  font-size: 18px;
  letter-spacing: 0.5px;
}

.container :global(.ant-modal-footer),
.container :global(.ant-modal-body) {
  border: 1px solid #858585;
  background: #15181c;
}

.container :global(.ant-modal-body) {
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container :global(.ant-modal-footer) {
  border-top: none;
}
