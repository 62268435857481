.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background: #000;
}
.comonaut {
  background-image: url('../assets/images/COMONAUT.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 40%;
  left: 69.5%;
}
.subContainer {
  height: 50%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.title {
  font-family: 'HelveticaNeue-CondensedBlack', sans-serif;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  color: #fff;
  font-size: 38px;
  font-weight: 600;
  position: relative;
  margin: 50px 0 70px 0;
  letter-spacing: 0.4px;
}
.logo {
  display: block;
  width: 65px;
  height: auto;
}
.contentFooterContainer {
  justify-content: space-between;
}
.googleLogin {
  background: none;
  border: none;
  cursor: pointer;
}
.googleLogin:focus,
.googleLogin:hover,
.googleLogin:active {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}
.title span:first-of-type {
  margin-right: 15px;
}
