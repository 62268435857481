.button {
  background-image: url('../../assets/images/teamwork.svg');
  background-repeat: no-repeat;
  width: 21px;
  height: 21px;
  margin-top: 3px;
  margin-bottom: 9px;
}
.container {
  width: 210px;
}
.group {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.tooltip {
  top: 140px !important;
}
.tooltip :global(.ant-tooltip-inner) {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: black;
  padding: 20px;
}
.tooltip :global(.ant-tooltip-arrow) {
  border-left: 1px solid #f06;
  border-top: 1px solid #f06;
  background-color: black;
  z-index: 100;
  width: 9px;
  height: 9px;
}
