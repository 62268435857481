.fixedHeader {
  justify-content: space-between;
  background: black;
  padding: 0;
}

.fixedHeaderCustomerList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background: black;
  padding: 0;
  height: 90px;
}

.MenuRight {
  color: #fff;
  margin: 0 24px;
  float: right;
  cursor: hand;
  z-index: 150;
}

.logo {
  margin: 5px 0 0 45px;
}

.breadcrumb {
  margin: 10px 0 10px 5%;
  line-height: 25px;
  float: left;
  width: 50%;
}
