.container {
  text-align: center;
  background-color: transparent;
  font-size: 14px;
  font-weight: 100;
  padding-bottom: 10px;
}
.link {
  color: rgba(255,255,255, 0.8);
  cursor: pointer;
  margin-right: 10px;
}
.link:last-of-type {
  margin-right: 0;
}
.footerContent {
  color: #f06;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
.footerContent .divider {
  background-color: #f06;
}
