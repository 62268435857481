.dropdownContainer {
  width: 203px;
  position: relative;
}

.actionsContainer {
  display: flex;
  flex-flow: row nowrap;
  border: 1px solid #f06;
  border-radius: 6px;
  cursor: pointer;
}

.preselectedAction,
.preselectedAction:hover,
.preselectedAction:disabled,
.preselectedAction:disabled:hover {
  width: calc(100% - 30px);
  border: none;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.preselectedAction:disabled,
.preselectedAction:disabled:hover,
.preselectedAction.noDropdown:disabled,
.preselectedAction.noDropdown:disabled:hover {
  background: #000;
}

.preselectedAction.noDropdown,
.preselectedAction.noDropdown:hover,
.preselectedAction.noDropdown:disabled,
.preselectedAction.noDropdown:disabled:hover {
  width: 100%;
  border: none;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.dropdownTrigger {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 30px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.dropdownMenu {
  position: absolute;
  left: 1.5px;
  z-index: 1000;
  width: 200px;
}

.menuVisible {
  visibility: visible;
}

.menuHidden {
  visibility: hidden;
}
