.noDataPlaceholder {
  box-sizing: content-box;
  min-width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  margin: 0 0 0 10px;
}

.verticalBarChart {
  box-sizing: content-box;
}
