/* eslint-disable no-descending-specificity */
.mainContainer {
  background-color: #000;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 0;
  padding-bottom: 0;
}

.row {
  border-color: none;
  font-size: 15px;
  font-weight: 300;
}

.notification :global(.ant-notification-notice-close) * {
  color: #fff;
}

:global(.ant-table-tbody) > tr > td.category {
  width: 300px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  padding-top: 5px;
  padding-bottom: 5px;
}

.mainContainer tr.selectedRow > td,
.mainContainer tr.selectedRow:hover > td {
  background: #000;
}
.mainContainer :global(.ant-table-tbody) tr.selectedRow > td,
.mainContainer :global(.ant-table-tbody) tr.selectedRow:hover > td,
.mainContainer :global(.ant-table-tbody) > tr.row > td {
  border-bottom-color: #000;
}

.mainContainer :global(.ant-table-selection-column .ant-checkbox .ant-checkbox-inner),
.mainContainer :global(.ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner) {
  border-radius: 50%;
  background-color: #000;
  border-color: rgba(255, 255, 255, 0.6);
}

.mainContainer :global(.ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner) {
  background: #04cd98;
  border-color: #04cd98;
}

.mainContainer
:global(.ant-table-selection-column .ant-checkbox-indeterminate .ant-checkbox-inner) {
  border-color: #04cd98;
}

.mainContainer :global(.ant-table-selection-column .ant-checkbox-checked)::after {
  border: none;
}

.mainContainer
:global(.ant-table-selection-column .ant-checkbox-indeterminate .ant-checkbox-inner)::after {
  background-color: #04cd98;
}

.mainContainer :global(.ant-table-row.ant-table-row-level-0).row:hover td {
  background: #000;
}

.mainContainer :global(.ant-table-footer),
.mainContainer :global(.ant-table-row.ant-table-row-level-0.ant-table-row-selected).row td {
  background: #000;
}

.mainContainer :global(.ant-select-selection) {
  height: 32px;
  padding: 7px 0;
}

.mainContainer :global(.ant-select-selection .ant-select-arrow) {
  top: 13px;
}

.mainContainer :global(.ant-select-selection .ant-select-arrow) svg {
  height: 12px;
}

.mainContainer :global(.ant-table-footer)::before {
  background: #000;
}

.mainContainer :global(.ant-table-footer) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
}

.saveButton {
  background: #f06;
}

.saveButton:disabled,
.saveButton:disabled:hover {
  background: #000;
}

.modalTitle {
  padding-top: 10px;
}

.modalTitleContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 0;
}

.titleSearchContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-top: 0;
}

.searchBar {
  margin-top: 10px;
  height: 29px;
}

.searchBar :global(.ant-input-prefix) {
  left: 7px;
}

.titleSearchContainer :global(.ant-input-affix-wrapper).searchBar :global(.ant-input-suffix) {
  right: 7px;
}

.categoriesModal {
  border: 1px solid #515152;
  background: #000;
  padding-bottom: 0;
  border-radius: 7px;
}

.categoriesModal :global(.ant-modal-header) {
  border-color: #000;
  background: #000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  height: auto;
  padding-right: 5px;
  padding-left: 20px;
  width: 100%;
}

.categoriesModal :global(.ant-modal-body) {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top: none;
  overflow-y: scroll;
  padding: 0;
  min-height: 0;
  border-color: #000;
}

.categoriesModal :global(.ant-modal-close) {
  display: none;
}

.categoriesModal :global(.ant-modal-footer) {
  background: #000;
  border-top: none;
}
