.dropdown {
  cursor: pointer;
  color: #fff;
}
.menuItem {
  list-style-type: none;
}
li.menuTitle,
li.menuTitle:hover,
li.menuTitle:active,
li.menuTitle:focus {
  cursor: default;
  color: #fff;
}
