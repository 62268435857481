.mainContainer,
.section {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.mainContainer {
  overflow-x: scroll;
}

.drawerSectionHeaders {
  margin: 0 0 20px 0;
  padding-left: 5px;
  font-size: 13px;
  line-height: 22px;
  background-color: #f06;
  font-weight: 400;
  width: 100%;
}

.userDetails {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  word-wrap: break-word;
  width: 100%;
  padding: 0 0 10px 10px;
  color: #fff;
}

.linkToInstagram {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
}

.linkToInstagram:link,
.linkToInstagram:visited {
  text-decoration: none;
}

.infoBoxHorizontal {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}

.details {
  text-transform: capitalize;
  font-weight: 450;
  padding: 5px 0 0 0;
  font-size: 15px;
  width: 70%;
  min-width: 170px;
}

.data {
  display: flex;
  justify-content: flex-end;
  font-weight: 300;
  color: rgb(199, 197, 197);
  text-transform: capitalize;
  font-size: 14px;
  letter-spacing: 0.4px;
  width: 60%;
}

.bioContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  word-wrap: break-word;
  width: 100%;
  text-transform: capitalize;
  font-weight: 450;
  font-size: 15px;
  margin-bottom: 20px;
  color: #fff;
}

.bio {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  font-weight: 300;
  color: rgb(199, 197, 197);
  padding-left: 7px;
  width: 100%;
  letter-spacing: 0.4px;
  font-size: 14px;
}

.timeline {
  display: flex;
  flex-flow: row wrap;
  height: 480px;
  max-width: 376px;
  border: 1px solid #E4E4E4;
  border-left: none;
  overflow: scroll;
  padding-right: 0;
}

.thumbnail {
  width: 125px;
  height: 125px;
}

.thumbnail:last-of-type {
  border-bottom: none;
}

.close {
  color: #fff;
  margin: 0;
  padding: 0;
  height: 22px;
  float: right;
  border: none !important;
}

.drawer :global(.ant-drawer-wrapper-body) {
  background-color: black;
  position: fixed;
}

.drawer :global(.ant-drawer-content-wrapper) {
  padding-bottom: 10%;
}

.drawer :global(.ant-drawer-body) {
  background-color: #15181c;
  border: 2px solid black;
  border-radius: 10px;
}

.avatar {
  margin-top: 8px;
}

.failedCheckContainer {
  background-color: rgba(255, 0, 0, 0.3);
  border: 1px solid red;
}

.failedCheckTitle {
  background-color: rgba(255, 0, 0, 0.3);
}

.otherProjectsContainer {
  border: 1px solid #fff;
}

.otherProjectsTitle {
  background-color: rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 1426px) {
  .timeline {
    width: 100%;
  }
  .thumbnail {
    width: 50%;
    height: 36%;
  }
  .addBorder:last-of-type {
    border-right: 1px solid #E4E4E4;
  }
}

@media only screen and (max-width: 1395px) {
  .section {
    align-items: center;
  }
  .bioContainer {
    width: 100%;
  }
  .bio {
    justify-content: flex-end;
    text-align: right;
    width: 100%;
  }
  .details {
    width: 100%;
  }
  .userDetails {
    padding-left: 0;
  }
}
