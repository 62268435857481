.details {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
}

.detailsContainer {
  width: 100%;
  padding: 0 10px;
}

.drawerSectionHeaders {
  margin: 0 0 20px 0;
  padding-left: 5px;
  font-size: 13px;
  line-height: 22px;
  background-color: #f06;
  font-weight: 400;
  width: 100%;
}

.close {
  color: #fff;
  margin: 0;
  padding: 0;
  height: 22px;
  float: right;
  border: none !important;
}

.detailEntry {
  display: flex;
  justify-content: space-between;
  color: #fff;
  margin-bottom: 5px;
}

.userDetails {
  font-weight: 400;
  color: #fff;
}

.drawer :global(.ant-drawer-wrapper-body) {
  background-color: black;
  position: fixed;
}

.drawer :global(.ant-drawer-content-wrapper) {
  padding-bottom: 10%;
}

.drawer :global(.ant-drawer-body) {
  background-color: #15181c;
  border: 2px solid black;
  border-radius: 10px;
}
