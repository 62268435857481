.mainContainer {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  background-color: #15181c;
}

.mainContainer :global(.ant-table-placeholder) th,
.mainContainer thead:global(.ant-table-thead) th,
.mainContainer tr > td {
  border: none;
  box-sizing: border-box;
  font-weight: 500;
}

.mainContainer :global(.ant-table-thead) {
  font-size: 15px;
  font-weight: 500;
  position: relative;
  text-align: center;
}

.mainContainer :global(.ant-empty-description) {
  color: #fff;
}

.mainContainer :global(.ant-table) {
  padding: 10px;
}

.mainContainer :global(.ant-table-placeholder),
.mainContainer :global(.ant-table-body) {
  border-top: 1px solid #f06;
  border-bottom: none;
}

.mainContainer tr > td {
  border: none;
  font-weight: 100;
}
.tableRowSelected > td:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.tableRowSelected > td:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tableRow:hover > td:last-of-type,
.tableRowSelected:hover > td:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tableRow:hover > td:first-of-type,
.tableRowSelected:hover > td:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.mainContainer .tableRowSelected,
.mainContainer tr.tableRowSelected:hover > td {
  background: #f06 !important;
}

.mainContainer thead:global(.ant-table-thead) th :global(.ant-table-column-sorter-inner) {
  margin: 0 0 1px 1px;
}

.mainContainer :global(.ant-table-column-sorter-up.on) *,
.mainContainer :global(.ant-table-column-sorter-down.on) * {
  color: #f06;
}

.lowDeepdiveScore {
  background-color: rgba(255, 0, 0, 0.3);
}
