.collapsibleSubcontainer {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}
.dataContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-top: 5px;
  width: 100%;
}
.colTitle {
  text-decoration: underline;
  padding-bottom: 3px;
}
.dataSubcol {
  padding-left: 10px;
  margin-bottom: 3px;
  text-align: left;
}
.projectSubcol {
  width: 39%;
}
.dataItemSubcol {
  width: 28%;
  text-align: center;
  padding-right: 10px;
}
.link {
  cursor: pointer;
  color: #fff;
}
