.default {
  padding: 5px 5px 5px 5px;
  text-align: center;
  border: none;
}

.defaultSize {
  font-size: 22px;
}

.default:hover,
.default:active,
.default:focus {
  border: none !important;
}
