.menuOption {
  box-sizing: border-box;
  height: 25px;
  filter: brightness(0) invert(65%);
}

.showMore {
  height: 28px;
  filter: brightness(0) invert(50%);
}

.close {
  height: 25px;
  filter: brightness(0) invert(50%);
  cursor: pointer;
}

.star {
  width: 22px;
}
