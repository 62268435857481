@import '~nprogress/nprogress.css';

html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: 'Noto', sans-serif;
  font-size: 14px;
  height: 100%;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.8);
}

.recharts-text.recharts-cartesian-axis-tick-value {
  fill: #bababa;
}
