.default {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: rgba(255, 255, 255, 0.9);
}
.default:hover,
.default:active {
  background: #f06;
  border-color: #fff;
  color: #fff;
}
.inline {
  display: inline-flex;
}
