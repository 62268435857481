.fixedSidebar {
  height: 100%;
  position: fixed;
  top: 0;
  background-color: #15181c;
}
.width {
  width: 170px !important; /* overrides antd inline style */
  min-width: 170px !important; /* overrides antd inline style */
}
.Logo a {
  color: #fff;
  white-space: nowrap;
}

.Logo img {
  margin: 10px 15px 0 20px;
}

.mainMenu {
  position: relative;
  padding-top: 10vh;
  z-index: 10;
  background-color: #15181c;
}

.mainMenu img {
  float: left;
}

.expandMenu {
  margin: 20px 15px 0 32px;
}

.expandedMenu {
  display: flex;
  margin: 20px 15px 0 22px;
  flex-flow: row nowrap;
}

.trigger {
  cursor: pointer;
  transition: color 0.3s;
  padding: 0 5px 0 0;
  height: 20px;
  filter: brightness(0) invert(65%);
  font-size: 20px;
}

.expandTxt {
  filter: brightness(0) invert(65%);
  padding: 0 0 0 5px;
}

.trigger:hover {
  color: #1890ff;
  filter: brightness(1);
}

.pointerCursor {
  cursor: pointer;
}

.fixedSidebar a {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.cursorRestricted {
  cursor: not-allowed;
}

.influencerPool {
  font-weight: 800;
}

.mainMenu :global(.ant-menu-item-selected) a {
  color: #f06;
  font-weight: 600;
}

.divider:global(.ant-menu-item-divider) {
  background: rgba(255, 255, 255, 0.3);
}
