:global(.optionSelect) :global(.ant-select-selection),
.disabled :global(.ant-select-selection) {
  display: block;
  text-transform: capitalize;
  width: 200px;
}

.disabled :global(.ant-select-selection) {
  padding-top: 7px;
}
