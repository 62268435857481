.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
}
.input {
  margin-top: 15px;
}

.LoginFormButton {
  width: 164px;
  height: 48px;
  border-radius: 12px;
  margin-top: 25px;
  font-size: 18px;
}
.input i svg {
  width: 30px;
  height: 30px;
}
.input input::placeholder {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.8);
}
:global(.ant-input-affix-wrapper).input input:global(.ant-input) {
  padding-left: 50px;
  font-size: 18px;
  max-width: 265px;
  min-width: 150px;
  height: 48px;
  border-radius: 12px;
  border: 1px solid #707070;
  background-color: #a3a3a3;
}
.alert {
  display: flex;
  justify-content: center;
  background: #000;
  color: red;
  border: none;
  border-radius: 12px;

}
