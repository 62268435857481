.sidebarLayout {
  min-height: 100vh;
  background-color: black;
}

.sidebar {
  margin-left: 170px;
  background-color: black;
}

.sidebarCollapsed {
  margin-left: 70px;
  background-color: black;
}

.content {
  margin: 0 5%;
  background-color: black;
}
