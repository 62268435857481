.default {
  margin: 5px 5px 0 0;
}
.container {
  line-height: 10px;
  position: relative;
}
.halo {
  position: absolute;
  border-radius: 50%;
  border: 1.5px solid #f06;
  left: -4px;
  top: 1px;
}
.default:hover {
  cursor: pointer;
}
