/* avatar */
.avatarContainer {
  position: relative;
  width: 50px;
}

.star {
  position: absolute;
  bottom: 0;
  right: -5px;
  width: 22px;
  cursor: pointer;
  outline: none;
  background: transparent;
}

.starNoPointer {
  cursor: default;
}

.avatar {
  width: 5%;
  min-width: 70px;
}

.avatarDrawerOpen {
  width: 11%;
  min-width: 70px;
}

/* username */
.username {
  width: 10%;
  max-width: 180px;
  min-width: 120px;
}

.usernameDrawerOpen {
  width: 10%;
  min-width: 136px;
}

:global(.ant-table-thead > tr > th).username,
:global(.ant-table-thead > tr > th).usernameDrawerOpen {
  text-align: left;
}


/* follower following engScore credebility Score */
.followers,
.following {
  width: 9%;
  min-width: 100px;
  max-width: 100px;
  text-align: center;
  word-break: break-all;
}

.followers [class*='anticon'] svg,
.following [class*='anticon'] svg,
.engScore [class*='anticon'] svg {
  height: 10px;
  margin: 0;
  padding: 0;
}

.engScore,
.credScore {
  width: 8%;
  min-width: 80px;
  max-width: 100px;
  text-align: center;
}

:global(.ant-table-thead > tr >th) {
  text-align: center;
}

.topCountryOfAudience,
.topGenderOfAudience {
  width: 9%;
  min-width: 80px;
  max-width: 100px;
  text-align: center;
  word-break: break-all;
}

.credScoreDrawerOpen {
  width: 15%;
  text-align: center;
}

/* final call buttons column */
.finalCall {
  width: 120px;
}
.buttons {
  display: flex;
  justify-content: center;
}

.finalCall [class*='anticon'] svg {
  height: 10px;
  margin: 0;
}
.votesButton {
  color: #fff;
  text-transform: uppercase;
  background-color: #15181c;
  width: 45px;
  justify-content: center;
  padding: 0;
  margin-right: 5px;
}
.votesButton:last-of-type {
  margin-right: 0;
}
.votesButton:hover {
  color: #fff;
  background-color: #15181c;
}

.finalCallDrawerOpen {
  width: 20%;
  text-align: center;
}

/*  comment column */
.comment {
  min-width: 150px;
}
.commentDrawerOpen {
  width: 30%;
}

.categoriesWidth {
  width: 150px;
}

.categories {
  min-width: 70px;
  min-width: 150px;
  text-align: center;
  width: 14%;
  word-break: break-all;
}

/* misc text align */
.centerText {
  text-align: center;
}

/* decision button styles */
.yes,
.yes:focus {
  border: 1px solid rgb(1, 255, 1);
  background: transparent;
}

.yesClicked,
.yesClicked:focus,
.yesClicked:hover,
.yes:hover {
  border: 1px solid rgb(1, 255, 1);
  background-color: rgb(1, 255, 1);
}

.no,
.no:focus {
  border: 1px solid rgb(255, 0, 0);
  background: transparent;
}

.noClicked,
.noClicked:focus,
.noClicked:hover,
.no:hover {
  border: 1px solid rgb(255, 0, 0);
  background-color: rgb(255, 0, 0);
}

:global(.ant-input.addCommentTextarea) {
  width: 85%;
  float: right;
  color: #fff;
  margin-right: 10px;
  max-width: 450px;
}
.addCommentTextarea::placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.addCommentTextarea:global(.ant-input-disabled)::placeholder {
  color: rgba(21, 24, 28, 0.25);
}

:global(.ant-table-thead th.ant-table-column-has-sorters) {
  text-align: center;
}

/* delete button */
.deleteButton {
  width: 50px;
  min-width: 50px;
  text-align: center;
}
:global(.ant-table-thead) th.deleteButton {
  padding-left: 0;
  padding-right: 0;
}

/* Column title */
.columnTitleContainer {
  display: inline-flex;
  flex-flow: column wrap;
}

:global(.ant-table .ant-table-selection-column) {
  min-width: 60px;
}
