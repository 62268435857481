.drawerSectionHeaders {
  margin: 0 0 20px 0;
  padding-left: 5px;
  font-size: 13px;
  line-height: 22px;
  background-color: #f06;
  font-weight: 400;
  width: 100%;
}

.genderCountriesContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 20px;
}

.chartHeader {
  margin-left: 10px;
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 17px;
  color: rgb(255, 255, 255);
  background: linear-gradient(to bottom, #15181c 0%, #15181c 78%, #f06 78%, #f06 100%);
  font-weight: 400;
}

.genderSplit {
  width: 140px;
  margin-right: 5px;
}

.ageGenderChart {
  width: 300px;
  height: 175px;
}
