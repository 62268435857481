.mainContainer {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}
.title {
  padding: 5px;
  padding-bottom: 7px;
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
}
.title:focus {
  outline: none;
}
.trigger {
  width: 15px;
  margin-right: 7px;
}
