.pageTop {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0;
}

.headings {
  display: flex;
  flex-flow: column nowrap;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  font-size: 20px;
  font-weight: 200;
}

.subHeader {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 25px;
}

.dashboardContainer {
  display: flex;
  flex-flow: row nowrap;
  background-color: #15181c;
  border-radius: 5px;
  padding: 10px 15px 5px 15px;
  margin-bottom: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.userConsole {
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
  background-color: #15181c;
  border-radius: 3px;
  align-items: flex-end;
}

.rightMargin {
  margin-right: 25px;
}

.checkmarksNoOfYes {
  width: 110px;
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
  color: green;
  height: 70px;
}

.checkmarksDecisions {
  width: 90px;
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
  height: 70px;
}

.decisionsTable {
  display: flex;
  flex-flow: column nowrap;
  font-size: 14px;
  text-align: left;
  padding-bottom: 4px;
}

.checkmarksDecisions span,
.checkmarksNoOfYes span {
  font-size: 13px;
  margin-top: 1px;
  margin-bottom: 2px;
}

.decisionsTable p {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 300;
}

.headerTableContainer {
  padding: 0;
  box-sizing: border-box;
  transition-duration: 0.2s;
}

.yes,
.yes:focus {
  border: 1px solid rgb(1, 255, 1);
  background: transparent;
}

.yesClicked,
.yesClicked:focus,
.yesClicked:hover,
.yes:hover {
  border: 1px solid rgb(1, 255, 1);
  background-color: rgb(1, 255, 1);
}

.no,
.no:focus {
  border: 1px solid rgb(255, 0, 0);
  background: transparent;
}

.noClicked,
.noClicked:focus,
.noClicked:hover,
.no:hover {
  border: 1px solid rgb(255, 0, 0);
  background-color: rgb(255, 0, 0);
}

.noViewOnly,
.yesViewOnly,
.noViewOnly:hover,
.noViewOnly:focus,
.yesViewOnly:hover,
.yesViewOnly:focus {
  cursor: not-allowed;
}

.finalCall :global(.anticon) svg {
  height: 10px;
  margin: 0;
}
.loading * {
  color: #fff;
}

.loading i {
  font-size: 50px;
  padding-bottom: 20px;
}

.yesVotes {
  color: rgb(1, 255, 1);
}

.noVotes {
  color: rgb(255, 0, 0);
}

.bigPinkButton,
.bigPinkButton:focus {
  margin-right: 25px;
  font-size: 14px;
  font-weight: 600;
  height: 95%;
  padding-top: 12px;
}

.csvFollowerContainer {
  display: flex;
  flex-flow: column nowrap;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5.5px;
  justify-content: flex-start;
  align-items: center;
}

.buttonsContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}
.buttonsSubcontainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.downloadIcon {
  width: 28px;
}
.refreshIcon {
  width: 21px;
  height: 21px;
  margin-top: 3px;
  margin-bottom: 9px;
}
.filtersHeader {
  width: 90%;
  line-height: 15px;
  font-weight: 500;
  letter-spacing: 0.4px;
  font-size: 13px;
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 3px;
  margin-bottom: 0;
}
.platformToggle {
  margin-bottom: 15px;
}
.radioBtnContainer {
  display: flex;
  flex-flow: column;
  width: 130px;
  margin-left: 30px;
}
.radioField {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 60px;
  font-size: 13px;
}
.radioButton {
  display: block;
  margin-bottom: 3px;
}
.radioContainer {
  position: relative;
}
.radioPoint {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f06;
  top: 6px;
  right: 12.5px;
}
.radioBtnContainer .filtersHeader {
  margin-bottom: 5px;
  line-height: 15px;
}
.radioButton :global(.ant-radio-inner) {
  border-color: rgba(255, 255, 255, 0.5);
  width: 17px;
  height: 17px;
}
.radioButton :global(.ant-radio-checked .ant-radio-inner) {
  border-color: #f06;
}
.radioButton :global(.ant-radio-checked .ant-radio-inner::after) {
  display: none;
}
.checkboxContainer .filtersHeader:last-of-type {
  margin-bottom: 3px;
}

.detailsBtn {
  height: 29px;
  width: 30px;
  border: none;
  background-image: url('../../../assets/images/hiring-white.svg');
  background-repeat: no-repeat;
  background-size: 25px 29px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.detailsBtn:hover,
.detailsBtn:focus,
.detailsBtn:active {
  background-repeat: no-repeat;
  background-size: 25px 29px;
  background-color: #15181c;
  background-image: url('../../../assets/images/hiring-white.svg');
  border: none;
}
