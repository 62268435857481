.checkmark {
  display: flex;
  flex-flow: row nowrap;
}
.checkmark * {
  height: 23px;
}
.inputStyle {
  display: flex;
  flex-flow: row nowrap;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  align-items: flex-end;
  padding-bottom: 1px;
  margin-bottom: 0;
  font-weight: 300;
}
.checkmark :global(.ant-checkbox) {
  height: 16px;
  align-self: flex-end;
}
.checkmark input ~ :global(.ant-checkbox-inner) {
  background: transparent;
  border-color: #f06;
  height: 16px;
}
.checkmark input:hover ~ :global(.ant-checkbox-inner) {
  background-color: rgba(255, 255, 255, 0.2);
}
.checkmark input:checked ~ :global(.ant-checkbox-inner) {
  background-color: #f06;
}
