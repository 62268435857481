.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.container h1 {
  color: #fff;
}

.error {
  color: #fff;
  text-align: center;
}
