html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: 'Noto', sans-serif;
  font-size: 14px;
  height: 100%;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.8);
}

.recharts-text.recharts-cartesian-axis-tick-value {
  fill: #bababa;
}

.StatusIndicator_online__2q-3A {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00ff09;
  border: 2px solid #FFF;
  z-index: 100;
}

.Avatar_default__Objsj {
  margin: 5px 5px 0 0;
}
.Avatar_container__2_pP_ {
  line-height: 10px;
  position: relative;
}
.Avatar_halo__3HvNB {
  position: absolute;
  border-radius: 50%;
  border: 1.5px solid #f06;
  left: -4px;
  top: 1px;
}
.Avatar_default__Objsj:hover {
  cursor: pointer;
}

.UserMenu_dropdown__1MpNC {
  cursor: pointer;
  color: #fff;
}
.UserMenu_menuItem__3IwQG {
  list-style-type: none;
}
li.UserMenu_menuTitle__26fIG,
li.UserMenu_menuTitle__26fIG:hover,
li.UserMenu_menuTitle__26fIG:active,
li.UserMenu_menuTitle__26fIG:focus {
  cursor: default;
  color: #fff;
}

.Logo_logo__1KTRC {
  width: 40px;
  height: auto;
}

.Logo_bigLogo__o8Yd4 {
  width: 50px;
  height: auto;
}

.Breadcrumb_breadcrumb__1JQCo .ant-breadcrumb a,
.Breadcrumb_breadcrumb__1JQCo .ant-breadcrumb-separator {
  font-weight: 400;
  -webkit-filter: brightness(0) invert(65%);
          filter: brightness(0) invert(65%);
}

.Breadcrumb_icon__1ieOM svg {
  padding: 1px 4px 0 0;
}

.Breadcrumb_breadcrumb__1JQCo .ant-breadcrumb a:hover,
.Breadcrumb_breadcrumb__1JQCo .ant-breadcrumb span:last-child a {
  text-decoration: none;
  -webkit-filter: none;
          filter: none;
  color: #f06;
  font-weight: 550;
}

.Header_fixedHeader__3D0l4 {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: black;
  padding: 0;
}

.Header_fixedHeaderCustomerList__AmHL0 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: black;
  padding: 0;
  height: 90px;
}

.Header_MenuRight__1DfoI {
  color: #fff;
  margin: 0 24px;
  float: right;
  cursor: hand;
  z-index: 150;
}

.Header_logo__2CwPW {
  margin: 5px 0 0 45px;
}

.Header_breadcrumb__2jn2k {
  margin: 10px 0 10px 5%;
  line-height: 25px;
  float: left;
  width: 50%;
}

.Sidebar_fixedSidebar__2DaFv {
  height: 100%;
  position: fixed;
  top: 0;
  background-color: #15181c;
}
.Sidebar_width__21K6s {
  width: 170px !important; /* overrides antd inline style */
  min-width: 170px !important; /* overrides antd inline style */
}
.Sidebar_Logo__30wBx a {
  color: #fff;
  white-space: nowrap;
}

.Sidebar_Logo__30wBx img {
  margin: 10px 15px 0 20px;
}

.Sidebar_mainMenu__uD_wx {
  position: relative;
  padding-top: 10vh;
  z-index: 10;
  background-color: #15181c;
}

.Sidebar_mainMenu__uD_wx img {
  float: left;
}

.Sidebar_expandMenu__34feB {
  margin: 20px 15px 0 32px;
}

.Sidebar_expandedMenu__2O419 {
  display: -webkit-flex;
  display: flex;
  margin: 20px 15px 0 22px;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
}

.Sidebar_trigger__krZm2 {
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  padding: 0 5px 0 0;
  height: 20px;
  -webkit-filter: brightness(0) invert(65%);
          filter: brightness(0) invert(65%);
  font-size: 20px;
}

.Sidebar_expandTxt__3HHcr {
  -webkit-filter: brightness(0) invert(65%);
          filter: brightness(0) invert(65%);
  padding: 0 0 0 5px;
}

.Sidebar_trigger__krZm2:hover {
  color: #1890ff;
  -webkit-filter: brightness(1);
          filter: brightness(1);
}

.Sidebar_pointerCursor__FE3np {
  cursor: pointer;
}

.Sidebar_fixedSidebar__2DaFv a {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.Sidebar_cursorRestricted__sJUU- {
  cursor: not-allowed;
}

.Sidebar_influencerPool__2YJ8W {
  font-weight: 800;
}

.Sidebar_mainMenu__uD_wx .ant-menu-item-selected a {
  color: #f06;
  font-weight: 600;
}

.Sidebar_divider__BCqZP.ant-menu-item-divider {
  background: rgba(255, 255, 255, 0.3);
}

.IconButtonsRepository_menuOption__rBnxS {
  box-sizing: border-box;
  height: 25px;
  -webkit-filter: brightness(0) invert(65%);
          filter: brightness(0) invert(65%);
}

.IconButtonsRepository_showMore__110RK {
  height: 28px;
  -webkit-filter: brightness(0) invert(50%);
          filter: brightness(0) invert(50%);
}

.IconButtonsRepository_close__1ESpL {
  height: 25px;
  -webkit-filter: brightness(0) invert(50%);
          filter: brightness(0) invert(50%);
  cursor: pointer;
}

.IconButtonsRepository_star__gpLVE {
  width: 22px;
}

.Footer_container__Y0sX1 {
  text-align: center;
  background-color: transparent;
  font-size: 14px;
  font-weight: 100;
  padding-bottom: 10px;
}
.Footer_link__KGq9i {
  color: rgba(255,255,255, 0.8);
  cursor: pointer;
  margin-right: 10px;
}
.Footer_link__KGq9i:last-of-type {
  margin-right: 0;
}
.Footer_footerContent__kBZBO {
  color: #f06;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
.Footer_footerContent__kBZBO .Footer_divider__2uFkQ {
  background-color: #f06;
}

.BasicLayout_sidebarLayout__3xVzx {
  min-height: 100vh;
  background-color: black;
}

.BasicLayout_sidebar__alkDW {
  margin-left: 170px;
  background-color: black;
}

.BasicLayout_sidebarCollapsed__3kqJ4 {
  margin-left: 70px;
  background-color: black;
}

.BasicLayout_content__1RhV0 {
  margin: 0 5%;
  background-color: black;
}

.NotFound_container__1nFtg {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh;
}

.NotFound_container__1nFtg h1 {
  color: #fff;
}

.NotFound_error__1vozE {
  color: #fff;
  text-align: center;
}

.LoadingMessage_fullscreen__3sMQx {
  position: fixed;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
}

.LoadingMessage_container__1BkuU {
  height: 100%;
  width: 100%;
}

.LoadingMessage_message__LdfsY {
  text-align: center;
}

.CustomerLayout_layout__1HMUO {
  min-height: 100vh;
  background-color: black;
}

.CustomerLayout_content__1rYRc {
  margin: 0 16px;
  background-color: black;
}

/*
to display add to parent component CSS file to Overlay parent tag:
   .parent {position: relative;}
   .parent:hover [class="engagement"] {display: flex;}
*/

.CountPhotoOverlay_countPhotoOverlay__26CLq {
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(21, 24, 28, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.CountPhotoOverlay_counts__2mYUJ {
  display: -webkit-flex;
  display: flex;
  width: 52px;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  color: #fff;
  padding: 2px 0;
}

.CountPhotoOverlay_icon__1FoWT {
  margin-right: 10px;
  box-sizing: border-box;
  height: 17px;
  cursor: pointer;
}

.Thumbnail_thumbnail__3h9oy {
  position: relative;
  width: 121px;
  height: 121px;
  overflow: hidden;
  border-bottom: 1px solid #E4E4E4;
  border-left: 1px solid #E4E4E4;
}
.Thumbnail_photo__29lzy {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.Thumbnail_photoNotLoaded__2AiP- {
  visibility: hidden;
}
.Thumbnail_loadingFailedCaption__lFDq1 {
  display: block;
  text-transform: uppercase;
  position: absolute;
  top: 45%;
  width: 100%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  max-width: 100%;
  color: #4f4f4f;
  white-space: break-spaces;
  text-align: center;
  font-weight: 400;
  padding: 10px;
}
.Thumbnail_displayCountOverlay__1x48h {
  display: none;
}
.Thumbnail_photoContainer__1Ebsz:hover .Thumbnail_displayCountOverlay__1x48h {
  display: -webkit-flex;
  display: flex;
}

.CollapsableSection_mainContainer__1pFTk {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}
.CollapsableSection_title__FxpH7 {
  padding: 5px;
  padding-bottom: 7px;
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
}
.CollapsableSection_title__FxpH7:focus {
  outline: none;
}
.CollapsableSection_trigger__3PhgG {
  width: 15px;
  margin-right: 7px;
}

.FailedChecks_collapsibleSubcontainer__7CRmN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  width: 100%;
}
.FailedChecks_dataContainer__2NFiD {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 5px;
  width: 100%;
}
.FailedChecks_dataName__3S1kf {
  width: 47%;
  padding-left: 10px;
}
.FailedChecks_dataCol__33wc8 {
  width: 53%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.FailedChecks_colTitle__TQMdg {
  text-decoration: underline;
  padding-bottom: 3px;
}
.FailedChecks_dataSubcol__jPFe- {
  width: 50%;
  padding-left: 15px;
  margin-bottom: 3px;
  text-align: left;
}
.FailedChecks_dataItemColor__YL2Tk {
  color: rgba(255,255,255,0.7);
}

.AccountProjects_collapsibleSubcontainer__CpQ8t {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  width: 100%;
}
.AccountProjects_dataContainer__2MJci {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 5px;
  width: 100%;
}
.AccountProjects_colTitle__2ZCPk {
  text-decoration: underline;
  padding-bottom: 3px;
}
.AccountProjects_dataSubcol__1I98J {
  padding-left: 10px;
  margin-bottom: 3px;
  text-align: left;
}
.AccountProjects_projectSubcol__2ArrZ {
  width: 39%;
}
.AccountProjects_dataItemSubcol__3iRa7 {
  width: 28%;
  text-align: center;
  padding-right: 10px;
}
.AccountProjects_link__FpP9B {
  cursor: pointer;
  color: #fff;
}

.optionSelectSources .ant-select-selection,
.optionSelect .ant-select-selection {
  background-color: #747474;
  padding-bottom: 0;
  margin: 0;
}

.optionSelect .ant-select-selection {
  width: 200px;
  height: 28px;
  padding: 0;
}
.optionSelectSources .ant-select-selection {
  width: 160px;
  height: 25px;
  padding: 0;
}
.ant-select-dropdown {
  background-color: #747474;
}
.optionSelectSources .ant-select-dropdown {
  background-color: #747474;
  font-size: 15px;
  font-weight: 300;
}
.optionSelectSources .ant-select-selection-selected-value,
.optionSelect .ant-select-selection-selected-value {
  line-height: 20px;
  font-weight: 300;
}
.optionSelectSources .ant-select-selection-selected-value {
  font-size: 15px;
  letter-spacing: 0;
  padding-top: 1px;
}
.optionSelectSources .ant-select-selection__rendered {
  display: -webkit-flex;
  display: flex;
  height: 22px;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.2px;
  -webkit-align-content: center;
          align-content: center;
  margin-left: 5px;
  margin-right: 5px;
}
.optionSelect .ant-select-selection-selected-value {
  padding-top: 4.5px;
  font-size: 18px;
}
.anticon {
  font-size: 18px;
}
.optionSelect .anticon,
.optionSelectSources .anticon {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.srcDropdown .ant-select-dropdown-menu-item {
  font-size: 15px;
  font-weight: 400;
}
.normalDropdown .ant-select-dropdown-menu-item {
  font-size: 18px;
  font-weight: 400;
}

.optionSelect .ant-select-selection,
.SelectCriteria_disabled__kfZPX .ant-select-selection {
  display: block;
  text-transform: capitalize;
  width: 200px;
}

.SelectCriteria_disabled__kfZPX .ant-select-selection {
  padding-top: 7px;
}

.Checkmark_checkmark__15TqC {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
}
.Checkmark_checkmark__15TqC * {
  height: 23px;
}
.Checkmark_inputStyle__14Wd7 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  padding-bottom: 1px;
  margin-bottom: 0;
  font-weight: 300;
}
.Checkmark_checkmark__15TqC .ant-checkbox {
  height: 16px;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}
.Checkmark_checkmark__15TqC input ~ .ant-checkbox-inner {
  background: transparent;
  border-color: #f06;
  height: 16px;
}
.Checkmark_checkmark__15TqC input:hover ~ .ant-checkbox-inner {
  background-color: rgba(255, 255, 255, 0.2);
}
.Checkmark_checkmark__15TqC input:checked ~ .ant-checkbox-inner {
  background-color: #f06;
}

.BorderedButton_default__z0jcs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: rgba(255, 255, 255, 0.9);
}
.BorderedButton_default__z0jcs:hover,
.BorderedButton_default__z0jcs:active {
  background: #f06;
  border-color: #fff;
  color: #fff;
}
.BorderedButton_inline__12KHQ {
  display: -webkit-inline-flex;
  display: inline-flex;
}

.IconButton_default__3JNJp {
  padding: 5px 5px 5px 5px;
  text-align: center;
  border: none;
}

.IconButton_defaultSize__3n07P {
  font-size: 22px;
}

.IconButton_default__3JNJp:hover,
.IconButton_default__3JNJp:active,
.IconButton_default__3JNJp:focus {
  border: none !important;
}

.Sources_collapsibleSubcontainer__33-1r {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  width: 100%;
}
.Sources_instructionsContainer__1ENAa {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
}
.Sources_instructionsContainer__1ENAa p {
  padding: 0;
  margin: 0;
}
.Sources_dataContainer__2xDhQ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 5px;
  width: 100%;
}
.Sources_colTitle__2vfdH {
  text-decoration: underline;
  padding-bottom: 3px;
}
.Sources_dataSubcol__3oHAp {
  width: 50%;
  padding-left: 10px;
  margin-bottom: 3px;
  text-align: left;
}
.Sources_typeSubcol__2KKxj {
  width: 50%;
  padding-left: 10px;
  margin-bottom: 3px;
  text-align: left;
}
.Sources_nameSubcol__1Gywr {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 50%;
  padding-left: 10px;
  margin-bottom: 3px;
  text-align: left;
}
.Sources_notification__EOwSS {
  margin-top: 30px;
  margin-left: 80px;
  letter-spacing: 0.3px;
}
.Sources_warning__2lEgU {
  border: 1px solid rgba(249, 40, 15, 1);
  background: rgba(249, 40, 15, 0.5);

}
.Sources_success__1icSY {
  border: 1px solid rgba(255,255,255,0.4);
}
.Sources_notification__EOwSS .ant-notification-notice-close * {
  color: #fff;
}
.Sources_button__1myPl {
  background-color: #f06;
  width: 80px;
  height: 30px;
  margin: 16px;
  border: 1px solid #fff;
  float: right;
}
.Sources_input__3EFcj {
  width: 100%;
  margin-right: 16px;
  height: 25px;
  font-size: 14px;
}
.Sources_manualSourceTitle__2x4Nu {
  margin-top: 15px;
  font-size: 16px;
  padding-left: 10px;
  font-weight: 500;
}
.Sources_sourceInstructions__3aPB2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  padding-left: 10px;
}
.Sources_refreshButton__3D1zm {
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0;
}
.Sources_refreshButton__3D1zm:hover,
.Sources_refreshButton__3D1zm:active {
  background-color: #15181c;
  fill: #f06;
  color: #f06;
}
.Sources_buttonContainer__1fEdq {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.charts_noDataPlaceholder__9m8fp {
  box-sizing: content-box;
  min-width: 165px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100px;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  margin: 0 0 0 10px;
}

.charts_verticalBarChart__3kKnV {
  box-sizing: content-box;
}

.UnderlinedTitle_header__2JjN0 {
  display: inline-block;
  background: -webkit-linear-gradient(top, #000 0%, #000 78%, #f06 78%, #f06 100%);
  background: linear-gradient(to bottom, #000 0%, #000 78%, #f06 78%, #f06 100%);
  color: #fff;
}
.UnderlinedTitle_small__3IBOw {
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.8);
  background: -webkit-linear-gradient(top, #15181c 0%, #15181c 78%, #f06 78%, #f06 100%);
  background: linear-gradient(to bottom, #15181c 0%, #15181c 78%, #f06 78%, #f06 100%);
  font-weight: 400;
  margin: 10px 0;
}
.UnderlinedTitle_medium__pVIvu {
  font-size: 15px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.8);
  background: -webkit-linear-gradient(top, #15181c 0%, #15181c 78%, #f06 78%, #f06 100%);
  background: linear-gradient(to bottom, #15181c 0%, #15181c 78%, #f06 78%, #f06 100%);
  font-weight: 400;
  margin: 10px 0;
}
.UnderlinedTitle_dashboard__UiKan {
  font-size: 35px;
  line-height: 38px;
  background: -webkit-linear-gradient(top, #000 0%, #000 75%, #f06 75%, #f06 100%);
  background: linear-gradient(to bottom, #000 0%, #000 75%, #f06 75%, #f06 100%);
  font-weight: 600;
  text-transform: uppercase;
  margin: 10px 0;
}
.UnderlinedTitle_big__1-Lq2 {
  height: 48px;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 30px;
}

.UnderlinedTitle_phone__1Fs37 {
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  background: -webkit-linear-gradient(top, #15181c 0%, #15181c 78%, #f06 78%, #f06 100%);
  background: linear-gradient(to bottom, #15181c 0%, #15181c 78%, #f06 78%, #f06 100%);
  font-weight: 600;
  margin: 0;
  margin-bottom: 3px;
  margin-right: 10px;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.Deepdive_drawerSectionHeaders__130pj {
  margin: 0 0 20px 0;
  padding-left: 5px;
  font-size: 13px;
  line-height: 22px;
  background-color: #f06;
  font-weight: 400;
  width: 100%;
}

.Deepdive_genderCountriesContainer__1feFq {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 20px;
}

.Deepdive_chartHeader__1sC33 {
  margin-left: 10px;
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 17px;
  color: rgb(255, 255, 255);
  background: -webkit-linear-gradient(top, #15181c 0%, #15181c 78%, #f06 78%, #f06 100%);
  background: linear-gradient(to bottom, #15181c 0%, #15181c 78%, #f06 78%, #f06 100%);
  font-weight: 400;
}

.Deepdive_genderSplit__3zvGB {
  width: 140px;
  margin-right: 5px;
}

.Deepdive_ageGenderChart__10NrX {
  width: 300px;
  height: 175px;
}

.UserDetailsDrawer_mainContainer__38vLE,
.UserDetailsDrawer_section__etzaX {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  width: 100%;
}

.UserDetailsDrawer_mainContainer__38vLE {
  overflow-x: scroll;
}

.UserDetailsDrawer_drawerSectionHeaders__3F0P5 {
  margin: 0 0 20px 0;
  padding-left: 5px;
  font-size: 13px;
  line-height: 22px;
  background-color: #f06;
  font-weight: 400;
  width: 100%;
}

.UserDetailsDrawer_userDetails__2x_hn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  word-wrap: break-word;
  width: 100%;
  padding: 0 0 10px 10px;
  color: #fff;
}

.UserDetailsDrawer_linkToInstagram__2PIye {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.UserDetailsDrawer_linkToInstagram__2PIye:link,
.UserDetailsDrawer_linkToInstagram__2PIye:visited {
  text-decoration: none;
}

.UserDetailsDrawer_infoBoxHorizontal__2xxg2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}

.UserDetailsDrawer_details__3JOBP {
  text-transform: capitalize;
  font-weight: 450;
  padding: 5px 0 0 0;
  font-size: 15px;
  width: 70%;
  min-width: 170px;
}

.UserDetailsDrawer_data__Z3doe {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  font-weight: 300;
  color: rgb(199, 197, 197);
  text-transform: capitalize;
  font-size: 14px;
  letter-spacing: 0.4px;
  width: 60%;
}

.UserDetailsDrawer_bioContainer__2l_pc {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  word-wrap: break-word;
  width: 100%;
  text-transform: capitalize;
  font-weight: 450;
  font-size: 15px;
  margin-bottom: 20px;
  color: #fff;
}

.UserDetailsDrawer_bio__wIlPP {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  text-align: right;
  font-weight: 300;
  color: rgb(199, 197, 197);
  padding-left: 7px;
  width: 100%;
  letter-spacing: 0.4px;
  font-size: 14px;
}

.UserDetailsDrawer_timeline__2-SZh {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  height: 480px;
  max-width: 376px;
  border: 1px solid #E4E4E4;
  border-left: none;
  overflow: scroll;
  padding-right: 0;
}

.UserDetailsDrawer_thumbnail__2BPL8 {
  width: 125px;
  height: 125px;
}

.UserDetailsDrawer_thumbnail__2BPL8:last-of-type {
  border-bottom: none;
}

.UserDetailsDrawer_close__1zMBB {
  color: #fff;
  margin: 0;
  padding: 0;
  height: 22px;
  float: right;
  border: none !important;
}

.UserDetailsDrawer_drawer__3xxXh .ant-drawer-wrapper-body {
  background-color: black;
  position: fixed;
}

.UserDetailsDrawer_drawer__3xxXh .ant-drawer-content-wrapper {
  padding-bottom: 10%;
}

.UserDetailsDrawer_drawer__3xxXh .ant-drawer-body {
  background-color: #15181c;
  border: 2px solid black;
  border-radius: 10px;
}

.UserDetailsDrawer_avatar__1ftFU {
  margin-top: 8px;
}

.UserDetailsDrawer_failedCheckContainer__3z3wa {
  background-color: rgba(255, 0, 0, 0.3);
  border: 1px solid red;
}

.UserDetailsDrawer_failedCheckTitle__1peSJ {
  background-color: rgba(255, 0, 0, 0.3);
}

.UserDetailsDrawer_otherProjectsContainer__2kbiN {
  border: 1px solid #fff;
}

.UserDetailsDrawer_otherProjectsTitle__1r4Lb {
  background-color: rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 1426px) {
  .UserDetailsDrawer_timeline__2-SZh {
    width: 100%;
  }
  .UserDetailsDrawer_thumbnail__2BPL8 {
    width: 50%;
    height: 36%;
  }
  .UserDetailsDrawer_addBorder__1FB9h:last-of-type {
    border-right: 1px solid #E4E4E4;
  }
}

@media only screen and (max-width: 1395px) {
  .UserDetailsDrawer_section__etzaX {
    -webkit-align-items: center;
            align-items: center;
  }
  .UserDetailsDrawer_bioContainer__2l_pc {
    width: 100%;
  }
  .UserDetailsDrawer_bio__wIlPP {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    text-align: right;
    width: 100%;
  }
  .UserDetailsDrawer_details__3JOBP {
    width: 100%;
  }
  .UserDetailsDrawer_userDetails__2x_hn {
    padding-left: 0;
  }
}

.CombinedDeepdiveDrawer_details__UfETA {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-bottom: 20px;
}

.CombinedDeepdiveDrawer_detailsContainer__1rIom {
  width: 100%;
  padding: 0 10px;
}

.CombinedDeepdiveDrawer_drawerSectionHeaders__2XDJF {
  margin: 0 0 20px 0;
  padding-left: 5px;
  font-size: 13px;
  line-height: 22px;
  background-color: #f06;
  font-weight: 400;
  width: 100%;
}

.CombinedDeepdiveDrawer_close__cZXeJ {
  color: #fff;
  margin: 0;
  padding: 0;
  height: 22px;
  float: right;
  border: none !important;
}

.CombinedDeepdiveDrawer_detailEntry__3xtnm {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: #fff;
  margin-bottom: 5px;
}

.CombinedDeepdiveDrawer_userDetails__3jDno {
  font-weight: 400;
  color: #fff;
}

.CombinedDeepdiveDrawer_drawer__2AgUr .ant-drawer-wrapper-body {
  background-color: black;
  position: fixed;
}

.CombinedDeepdiveDrawer_drawer__2AgUr .ant-drawer-content-wrapper {
  padding-bottom: 10%;
}

.CombinedDeepdiveDrawer_drawer__2AgUr .ant-drawer-body {
  background-color: #15181c;
  border: 2px solid black;
  border-radius: 10px;
}

.CustomerListTable_mainContainer__1A1rg {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  background-color: #15181c;
}

.CustomerListTable_mainContainer__1A1rg .ant-table-placeholder th,
.CustomerListTable_mainContainer__1A1rg thead.ant-table-thead th,
.CustomerListTable_mainContainer__1A1rg tr > td {
  border: none;
  box-sizing: border-box;
  font-weight: 500;
}

.CustomerListTable_mainContainer__1A1rg .ant-table-thead {
  font-size: 15px;
  font-weight: 500;
  position: relative;
  text-align: center;
}

.CustomerListTable_mainContainer__1A1rg .ant-empty-description {
  color: #fff;
}

.CustomerListTable_mainContainer__1A1rg .ant-table {
  padding: 10px;
}

.CustomerListTable_mainContainer__1A1rg .ant-table-placeholder,
.CustomerListTable_mainContainer__1A1rg .ant-table-body {
  border-top: 1px solid #f06;
  border-bottom: none;
}

.CustomerListTable_mainContainer__1A1rg tr > td {
  border: none;
  font-weight: 100;
}
.CustomerListTable_tableRowSelected__26ZAN > td:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.CustomerListTable_tableRowSelected__26ZAN > td:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.CustomerListTable_tableRow__2Kykf:hover > td:last-of-type,
.CustomerListTable_tableRowSelected__26ZAN:hover > td:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.CustomerListTable_tableRow__2Kykf:hover > td:first-of-type,
.CustomerListTable_tableRowSelected__26ZAN:hover > td:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.CustomerListTable_mainContainer__1A1rg .CustomerListTable_tableRowSelected__26ZAN,
.CustomerListTable_mainContainer__1A1rg tr.CustomerListTable_tableRowSelected__26ZAN:hover > td {
  background: #f06 !important;
}

.CustomerListTable_mainContainer__1A1rg thead.ant-table-thead th .ant-table-column-sorter-inner {
  margin: 0 0 1px 1px;
}

.CustomerListTable_mainContainer__1A1rg .ant-table-column-sorter-up.on *,
.CustomerListTable_mainContainer__1A1rg .ant-table-column-sorter-down.on * {
  color: #f06;
}

.CustomerListTable_lowDeepdiveScore__10Mw- {
  background-color: rgba(255, 0, 0, 0.3);
}

.CustomerLink_link__35L9Q {
  display: none;
  width: 140px;
}

.FollowerGroups_button__LDPl0 {
  background-image: url(/static/media/teamwork.d218536f.svg);
  background-repeat: no-repeat;
  width: 21px;
  height: 21px;
  margin-top: 3px;
  margin-bottom: 9px;
}
.FollowerGroups_container__1hSzZ {
  width: 210px;
}
.FollowerGroups_group__3FK9B {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.FollowerGroups_tooltip__3uNLw {
  top: 140px !important;
}
.FollowerGroups_tooltip__3uNLw .ant-tooltip-inner {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: black;
  padding: 20px;
}
.FollowerGroups_tooltip__3uNLw .ant-tooltip-arrow {
  border-left: 1px solid #f06;
  border-top: 1px solid #f06;
  background-color: black;
  z-index: 100;
  width: 9px;
  height: 9px;
}

.CheckmarkGroup_container__P5AXP {
  height: 70px;
}

/* avatar */
.columnOptions_avatarContainer__hC_1E {
  position: relative;
  width: 50px;
}

.columnOptions_star__2SZXl {
  position: absolute;
  bottom: 0;
  right: -5px;
  width: 22px;
  cursor: pointer;
  outline: none;
  background: transparent;
}

.columnOptions_starNoPointer__11wPv {
  cursor: default;
}

.columnOptions_avatar__25Zvc {
  width: 5%;
  min-width: 70px;
}

.columnOptions_avatarDrawerOpen__1e6Zt {
  width: 11%;
  min-width: 70px;
}

/* username */
.columnOptions_username__2QC5b {
  width: 10%;
  max-width: 180px;
  min-width: 120px;
}

.columnOptions_usernameDrawerOpen__1e1fc {
  width: 10%;
  min-width: 136px;
}

.ant-table-thead > tr > th.columnOptions_username__2QC5b,
.ant-table-thead > tr > th.columnOptions_usernameDrawerOpen__1e1fc {
  text-align: left;
}


/* follower following engScore credebility Score */
.columnOptions_followers__1rfh_,
.columnOptions_following__3d8GS {
  width: 9%;
  min-width: 100px;
  max-width: 100px;
  text-align: center;
  word-break: break-all;
}

.columnOptions_followers__1rfh_ [class*='anticon'] svg,
.columnOptions_following__3d8GS [class*='anticon'] svg,
.columnOptions_engScore__2Q1jv [class*='anticon'] svg {
  height: 10px;
  margin: 0;
  padding: 0;
}

.columnOptions_engScore__2Q1jv,
.columnOptions_credScore__1DYK_ {
  width: 8%;
  min-width: 80px;
  max-width: 100px;
  text-align: center;
}

.ant-table-thead > tr >th {
  text-align: center;
}

.columnOptions_topCountryOfAudience__1Emcn,
.columnOptions_topGenderOfAudience__2wctN {
  width: 9%;
  min-width: 80px;
  max-width: 100px;
  text-align: center;
  word-break: break-all;
}

.columnOptions_credScoreDrawerOpen__3Cu5Q {
  width: 15%;
  text-align: center;
}

/* final call buttons column */
.columnOptions_finalCall__2GOpW {
  width: 120px;
}
.columnOptions_buttons__3voc1 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.columnOptions_finalCall__2GOpW [class*='anticon'] svg {
  height: 10px;
  margin: 0;
}
.columnOptions_votesButton__3DkPe {
  color: #fff;
  text-transform: uppercase;
  background-color: #15181c;
  width: 45px;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0;
  margin-right: 5px;
}
.columnOptions_votesButton__3DkPe:last-of-type {
  margin-right: 0;
}
.columnOptions_votesButton__3DkPe:hover {
  color: #fff;
  background-color: #15181c;
}

.columnOptions_finalCallDrawerOpen__6AOEd {
  width: 20%;
  text-align: center;
}

/*  comment column */
.columnOptions_comment__2GjjG {
  min-width: 150px;
}
.columnOptions_commentDrawerOpen__3cMyz {
  width: 30%;
}

.columnOptions_categoriesWidth__3-Isx {
  width: 150px;
}

.columnOptions_categories__1XN5Q {
  min-width: 70px;
  min-width: 150px;
  text-align: center;
  width: 14%;
  word-break: break-all;
}

/* misc text align */
.columnOptions_centerText__1VKdR {
  text-align: center;
}

/* decision button styles */
.columnOptions_yes__2bBXh,
.columnOptions_yes__2bBXh:focus {
  border: 1px solid rgb(1, 255, 1);
  background: transparent;
}

.columnOptions_yesClicked__38dpp,
.columnOptions_yesClicked__38dpp:focus,
.columnOptions_yesClicked__38dpp:hover,
.columnOptions_yes__2bBXh:hover {
  border: 1px solid rgb(1, 255, 1);
  background-color: rgb(1, 255, 1);
}

.columnOptions_no__WBdi4,
.columnOptions_no__WBdi4:focus {
  border: 1px solid rgb(255, 0, 0);
  background: transparent;
}

.columnOptions_noClicked__3RTdS,
.columnOptions_noClicked__3RTdS:focus,
.columnOptions_noClicked__3RTdS:hover,
.columnOptions_no__WBdi4:hover {
  border: 1px solid rgb(255, 0, 0);
  background-color: rgb(255, 0, 0);
}

.ant-input.addCommentTextarea {
  width: 85%;
  float: right;
  color: #fff;
  margin-right: 10px;
  max-width: 450px;
}
.columnOptions_addCommentTextarea__2608F::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.columnOptions_addCommentTextarea__2608F::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.columnOptions_addCommentTextarea__2608F::placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.columnOptions_addCommentTextarea__2608F.ant-input-disabled::-webkit-input-placeholder {
  color: rgba(21, 24, 28, 0.25);
}
.columnOptions_addCommentTextarea__2608F.ant-input-disabled::-ms-input-placeholder {
  color: rgba(21, 24, 28, 0.25);
}
.columnOptions_addCommentTextarea__2608F.ant-input-disabled::placeholder {
  color: rgba(21, 24, 28, 0.25);
}

.ant-table-thead th.ant-table-column-has-sorters {
  text-align: center;
}

/* delete button */
.columnOptions_deleteButton__3io8g {
  width: 50px;
  min-width: 50px;
  text-align: center;
}
.ant-table-thead th.columnOptions_deleteButton__3io8g {
  padding-left: 0;
  padding-right: 0;
}

/* Column title */
.columnOptions_columnTitleContainer__U3czC {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
}

.ant-table .ant-table-selection-column {
  min-width: 60px;
}

.TooltipTag_categoryTagBox__y774F {
  width: calc(100% - 4.5px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: center;
          justify-content: center;
  height: 20px;
  border: 1px solid #f06;
  border-radius: 3px;
  margin-right: 4.5px;
  margin-top: 1.5px;
  margin-bottom: 1.5px;
  background: rgba(100, 0, 40, 0.5);
}

.TooltipTag_categoryTag__2dneR {
  width: auto;
  height: 20px;
  max-width: 95%;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(255, 255, 255, 0.8);
  padding: 0 5px;
}

.CategoriesColumnView_categoryContainer__2pxEt {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 46px;
  overflow-y: hidden;
}

.CategoriesColumnView_categories__2Agj1 {
  width: calc(100% - 39px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: center;
          align-items: center;
}

.CategoriesColumnView_showMoreButton__2R775 {
  cursor: pointer;
}

.CategoriesColumnView_containerWidth__H9djP {
  width: 200px;
}

.CustomerListPage_pageTop__34SCs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 0;
}

.CustomerListPage_headings__SZuYV {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
}

.CustomerListPage_header__2Z3fo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  font-size: 20px;
  font-weight: 200;
}

.CustomerListPage_subHeader__zEzl2 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 25px;
}

.CustomerListPage_dashboardContainer__1USAM {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  background-color: #15181c;
  border-radius: 5px;
  padding: 10px 15px 5px 15px;
  margin-bottom: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.CustomerListPage_userConsole__IVKVa {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  box-sizing: border-box;
  background-color: #15181c;
  border-radius: 3px;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.CustomerListPage_rightMargin__24GrM {
  margin-right: 25px;
}

.CustomerListPage_checkmarksNoOfYes__FP1_e {
  width: 110px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  text-align: left;
  color: green;
  height: 70px;
}

.CustomerListPage_checkmarksDecisions__uhGQ7 {
  width: 90px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  text-align: left;
  height: 70px;
}

.CustomerListPage_decisionsTable__ekIu5 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  font-size: 14px;
  text-align: left;
  padding-bottom: 4px;
}

.CustomerListPage_checkmarksDecisions__uhGQ7 span,
.CustomerListPage_checkmarksNoOfYes__FP1_e span {
  font-size: 13px;
  margin-top: 1px;
  margin-bottom: 2px;
}

.CustomerListPage_decisionsTable__ekIu5 p {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 300;
}

.CustomerListPage_headerTableContainer__36cTP {
  padding: 0;
  box-sizing: border-box;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
}

.CustomerListPage_yes__3sdUx,
.CustomerListPage_yes__3sdUx:focus {
  border: 1px solid rgb(1, 255, 1);
  background: transparent;
}

.CustomerListPage_yesClicked__hnWWE,
.CustomerListPage_yesClicked__hnWWE:focus,
.CustomerListPage_yesClicked__hnWWE:hover,
.CustomerListPage_yes__3sdUx:hover {
  border: 1px solid rgb(1, 255, 1);
  background-color: rgb(1, 255, 1);
}

.CustomerListPage_no__3t9TH,
.CustomerListPage_no__3t9TH:focus {
  border: 1px solid rgb(255, 0, 0);
  background: transparent;
}

.CustomerListPage_noClicked__2GuXG,
.CustomerListPage_noClicked__2GuXG:focus,
.CustomerListPage_noClicked__2GuXG:hover,
.CustomerListPage_no__3t9TH:hover {
  border: 1px solid rgb(255, 0, 0);
  background-color: rgb(255, 0, 0);
}

.CustomerListPage_noViewOnly__1wGyA,
.CustomerListPage_yesViewOnly__2_Uwn,
.CustomerListPage_noViewOnly__1wGyA:hover,
.CustomerListPage_noViewOnly__1wGyA:focus,
.CustomerListPage_yesViewOnly__2_Uwn:hover,
.CustomerListPage_yesViewOnly__2_Uwn:focus {
  cursor: not-allowed;
}

.CustomerListPage_finalCall__1xcLL .anticon svg {
  height: 10px;
  margin: 0;
}
.CustomerListPage_loading__17Yhi * {
  color: #fff;
}

.CustomerListPage_loading__17Yhi i {
  font-size: 50px;
  padding-bottom: 20px;
}

.CustomerListPage_yesVotes__iRkYS {
  color: rgb(1, 255, 1);
}

.CustomerListPage_noVotes__3O29i {
  color: rgb(255, 0, 0);
}

.CustomerListPage_bigPinkButton__1Khhf,
.CustomerListPage_bigPinkButton__1Khhf:focus {
  margin-right: 25px;
  font-size: 14px;
  font-weight: 600;
  height: 95%;
  padding-top: 12px;
}

.CustomerListPage_csvFollowerContainer__2Is8M {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5.5px;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

.CustomerListPage_buttonsContainer__1ojqw {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.CustomerListPage_buttonsSubcontainer__3xQ4_ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.CustomerListPage_downloadIcon__1WhPQ {
  width: 28px;
}
.CustomerListPage_refreshIcon__3PCuW {
  width: 21px;
  height: 21px;
  margin-top: 3px;
  margin-bottom: 9px;
}
.CustomerListPage_filtersHeader__1aZ9J {
  width: 90%;
  line-height: 15px;
  font-weight: 500;
  letter-spacing: 0.4px;
  font-size: 13px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  padding-bottom: 3px;
  margin-bottom: 0;
}
.CustomerListPage_platformToggle__292C1 {
  margin-bottom: 15px;
}
.CustomerListPage_radioBtnContainer__FX7kM {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  width: 130px;
  margin-left: 30px;
}
.CustomerListPage_radioField__29CVh {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
          flex-flow: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 60px;
  font-size: 13px;
}
.CustomerListPage_radioButton__2TrAW {
  display: block;
  margin-bottom: 3px;
}
.CustomerListPage_radioContainer__1fAgX {
  position: relative;
}
.CustomerListPage_radioPoint__3bjeM {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f06;
  top: 6px;
  right: 12.5px;
}
.CustomerListPage_radioBtnContainer__FX7kM .CustomerListPage_filtersHeader__1aZ9J {
  margin-bottom: 5px;
  line-height: 15px;
}
.CustomerListPage_radioButton__2TrAW .ant-radio-inner {
  border-color: rgba(255, 255, 255, 0.5);
  width: 17px;
  height: 17px;
}
.CustomerListPage_radioButton__2TrAW .ant-radio-checked .ant-radio-inner {
  border-color: #f06;
}
.CustomerListPage_radioButton__2TrAW .ant-radio-checked .ant-radio-inner::after {
  display: none;
}
.CustomerListPage_checkboxContainer__25oUS .CustomerListPage_filtersHeader__1aZ9J:last-of-type {
  margin-bottom: 3px;
}

.CustomerListPage_detailsBtn__1-0AP {
  height: 29px;
  width: 30px;
  border: none;
  background-image: url(/static/media/hiring-white.26501108.svg);
  background-repeat: no-repeat;
  background-size: 25px 29px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.CustomerListPage_detailsBtn__1-0AP:hover,
.CustomerListPage_detailsBtn__1-0AP:focus,
.CustomerListPage_detailsBtn__1-0AP:active {
  background-repeat: no-repeat;
  background-size: 25px 29px;
  background-color: #15181c;
  background-image: url(/static/media/hiring-white.26501108.svg);
  border: none;
}

.AddUsersModal_addToFinalListModal__1Reer .ant-modal-content {
  z-index: 1000;
  width: 500px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
}

.AddUsersModal_addToFinalListModal__1Reer .ant-modal-header {
  border-bottom: 2px solid #f06;
}

.AddUsersModal_addToFinalListModal__1Reer .ant-modal-content * {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
}

.AddUsersModal_addToFinalListModal__1Reer .ant-modal-footer {
  padding-top: 0;
  border: none;
}

.AddUsersModal_addToFinalListTextarea__3rlZr {
  font-size: 15px;
}
.AddUsersModal_creditWarning__2TUR6 {
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.2px;
}

.ConfirmActionModal_confirmModal__1UOWb .ant-modal-content {
  z-index: 100;
  width: 500px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  text-align: center;
}

.ConfirmActionModal_confirmModal__1UOWb .ant-modal-header {
  border-bottom: 2px solid #f06;
}

.ConfirmActionModal_confirmModal__1UOWb .ant-modal-content * {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
}

.ConfirmActionModal_confirmModal__1UOWb .ant-modal-footer {
  text-align: center;
  padding-top: 0;
  border: none;
}

.ConfirmActionModal_addToFinalListTextarea__3BeoT {
  font-size: 15px;
}

.FinalListTable_mainContainer__2Z7LD {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  background-color: #15181c;
}

.FinalListTable_mainContainer__2Z7LD .ant-table-placeholder th,
.FinalListTable_mainContainer__2Z7LD thead.ant-table-thead th,
.FinalListTable_mainContainer__2Z7LD tr > td {
  border: none;
  box-sizing: border-box;
  font-weight: 500;
}

.FinalListTable_mainContainer__2Z7LD .ant-table-thead {
  font-size: 15px;
  font-weight: 500;
  position: relative;
  text-align: center;
}

.FinalListTable_mainContainer__2Z7LD .ant-empty-description {
  color: #fff;
}

.FinalListTable_mainContainer__2Z7LD .ant-table {
  padding: 10px;
}

.FinalListTable_mainContainer__2Z7LD .ant-table-placeholder,
.FinalListTable_mainContainer__2Z7LD .ant-table-body {
  border-top: 1px solid #f06;
  border-bottom: none;
}

.FinalListTable_mainContainer__2Z7LD tr > td {
  border: none;
  font-weight: 100;
}
.FinalListTable_tableRowSelected__1eerx > td:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.FinalListTable_tableRowSelected__1eerx > td:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.FinalListTable_tableRow__1ieEw:hover > td:last-of-type,
.FinalListTable_tableRowSelected__1eerx:hover > td:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.FinalListTable_tableRow__1ieEw:hover > td:first-of-type,
.FinalListTable_tableRowSelected__1eerx:hover > td:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.FinalListTable_mainContainer__2Z7LD .FinalListTable_tableRowSelected__1eerx,
.FinalListTable_mainContainer__2Z7LD tr.FinalListTable_tableRowSelected__1eerx:hover > td {
  background: #f06 !important;
}

.FinalListTable_mainContainer__2Z7LD tr.FinalListTable_tableRowSelected__1eerx > td,
.FinalListTable_mainContainer__2Z7LD tr.FinalListTable_tableRowSelected__1eerx:hover > td {
  background: #f06;
}
.FinalListTable_mainContainer__2Z7LD .ant-table-tbody tr.FinalListTable_tableRowSelected__1eerx > td,
.FinalListTable_mainContainer__2Z7LD .ant-table-tbody tr.FinalListTable_tableRowSelected__1eerx:hover > td,
.FinalListTable_mainContainer__2Z7LD .ant-table-tbody > tr.FinalListTable_tableRow__1ieEw > td {
  border-bottom-color: #15181c;
}

.FinalListTable_mainContainer__2Z7LD .ant-table-selection-column .ant-checkbox .ant-checkbox-inner,
.FinalListTable_mainContainer__2Z7LD .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner {
  border-radius: 50%;
  background-color: #15181c;
  border-color: rgba(255, 255, 255, 0.6);
}

.FinalListTable_mainContainer__2Z7LD .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner {
  background: #04cd98;
  border-color: #04cd98;
}

.FinalListTable_mainContainer__2Z7LD
.ant-table-selection-column .ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: #04cd98;
}

.FinalListTable_mainContainer__2Z7LD .ant-table-selection-column .ant-checkbox-checked::after {
  border: none;
}

.FinalListTable_mainContainer__2Z7LD
.ant-table-selection-column .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #04cd98;
}

.FinalListTable_mainContainer__2Z7LD .ant-table-row.ant-table-row-level-0.FinalListTable_tableRow__1ieEw:hover td {
  background: #f06;
  cursor: pointer;
}

.FinalListTable_mainContainer__2Z7LD .ant-table-row.ant-table-row-level-0.ant-table-row-selected.FinalListTable_tableRow__1ieEw td {
  background: #15181c;
}

.FinalListTable_mainContainer__2Z7LD thead.ant-table-thead th .ant-table-column-sorter-inner {
  margin: 0 0 1px 1px;
}

.FinalListTable_mainContainer__2Z7LD .ant-table-column-sorter-up.on *,
.FinalListTable_mainContainer__2Z7LD .ant-table-column-sorter-down.on * {
  color: #f06;
}

.FinalListTable_lowDeepdiveScore__2NkbF {
  background-color: rgba(255, 0, 0, 0.3);
}

.ant-modal.ConfirmationModal_container__3qLEz {
  min-width: 520px;
  height: 500px;
}

.ConfirmationModal_yesButton__19UGO {
  border-color: #04cd98;
  width: 60px;
  height: 25px;
}

.ConfirmationModal_noButton__2wEq1 {
  width: 60px;
  height: 25px;
}

.ConfirmationModal_container__3qLEz .ant-modal-body .ConfirmationModal_content__1hfyq {
  font-size: 18px;
  letter-spacing: 0.5px;
}

.ConfirmationModal_container__3qLEz .ant-modal-footer,
.ConfirmationModal_container__3qLEz .ant-modal-body {
  border: 1px solid #858585;
  background: #15181c;
}

.ConfirmationModal_container__3qLEz .ant-modal-body {
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.ConfirmationModal_container__3qLEz .ant-modal-footer {
  border-top: none;
}

.SelectModal_closeIcon__18lwY {
  color: #fff;
  width: 40px;
  height: 40px;
}

.ant-modal.SelectModal_container__1ga6a {
  min-width: 520px;
}

.SelectModal_container__1ga6a .ant-modal-body,
.SelectModal_container__1ga6a .ant-modal-header {
  border: 1px solid #858585;
}

.SelectModal_container__1ga6a .ant-modal-content {
  border: none;
}

.SelectModal_container__1ga6a .ant-modal-header {
  background: #515152;
  border-bottom: none;
  height: auto;
}

.SelectModal_container__1ga6a .ant-modal-body {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top: none;
  max-height: 450px;
  min-height: 450px;
  overflow-y: scroll;
  padding: 0;
}

.SelectModal_container__1ga6a .ant-modal-header .ant-modal-title {
  font-size: 20px;
}

.SearchBar_searchBar__wcxgj {
  height: 40px;
  width: 17%;
  min-width: 200px;
}
.SearchBar_iconSize__3mN_v svg {
  width: 20px;
  height: 20px;
  color: rgba(255, 255, 255, 0.5);
}
.SearchBar_searchBar__wcxgj input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
  letter-spacing: 0.15px;
}
.SearchBar_searchBar__wcxgj input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
  letter-spacing: 0.15px;
}
.SearchBar_searchBar__wcxgj input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
  letter-spacing: 0.15px;
}
.ant-input-affix-wrapper.SearchBar_searchBar__wcxgj input.ant-input {
  padding-left: 40px;
  font-size: 18px;
  border: 1px solid #f06;
  background-color: #000;
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 100;
  letter-spacing: 0.15px;
}
.ant-input-affix-wrapper.SearchBar_searchBar__wcxgj input.ant-input:focus,
.ant-input-affix-wrapper.SearchBar_searchBar__wcxgj input.ant-input:active,
.ant-input-affix-wrapper.SearchBar_searchBar__wcxgj input.ant-input:hover {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

/* eslint-disable no-descending-specificity */
.CategoriesModal_mainContainer__12uNt {
  background-color: #000;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 0;
  padding-bottom: 0;
}

.CategoriesModal_row__vXwlL {
  border-color: none;
  font-size: 15px;
  font-weight: 300;
}

.CategoriesModal_notification__EveCK .ant-notification-notice-close * {
  color: #fff;
}

.ant-table-tbody > tr > td.CategoriesModal_category__2uLSN {
  width: 300px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  padding-top: 5px;
  padding-bottom: 5px;
}

.CategoriesModal_mainContainer__12uNt tr.CategoriesModal_selectedRow__3dP6h > td,
.CategoriesModal_mainContainer__12uNt tr.CategoriesModal_selectedRow__3dP6h:hover > td {
  background: #000;
}
.CategoriesModal_mainContainer__12uNt .ant-table-tbody tr.CategoriesModal_selectedRow__3dP6h > td,
.CategoriesModal_mainContainer__12uNt .ant-table-tbody tr.CategoriesModal_selectedRow__3dP6h:hover > td,
.CategoriesModal_mainContainer__12uNt .ant-table-tbody > tr.CategoriesModal_row__vXwlL > td {
  border-bottom-color: #000;
}

.CategoriesModal_mainContainer__12uNt .ant-table-selection-column .ant-checkbox .ant-checkbox-inner,
.CategoriesModal_mainContainer__12uNt .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner {
  border-radius: 50%;
  background-color: #000;
  border-color: rgba(255, 255, 255, 0.6);
}

.CategoriesModal_mainContainer__12uNt .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner {
  background: #04cd98;
  border-color: #04cd98;
}

.CategoriesModal_mainContainer__12uNt
.ant-table-selection-column .ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: #04cd98;
}

.CategoriesModal_mainContainer__12uNt .ant-table-selection-column .ant-checkbox-checked::after {
  border: none;
}

.CategoriesModal_mainContainer__12uNt
.ant-table-selection-column .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #04cd98;
}

.CategoriesModal_mainContainer__12uNt .ant-table-row.ant-table-row-level-0.CategoriesModal_row__vXwlL:hover td {
  background: #000;
}

.CategoriesModal_mainContainer__12uNt .ant-table-footer,
.CategoriesModal_mainContainer__12uNt .ant-table-row.ant-table-row-level-0.ant-table-row-selected.CategoriesModal_row__vXwlL td {
  background: #000;
}

.CategoriesModal_mainContainer__12uNt .ant-select-selection {
  height: 32px;
  padding: 7px 0;
}

.CategoriesModal_mainContainer__12uNt .ant-select-selection .ant-select-arrow {
  top: 13px;
}

.CategoriesModal_mainContainer__12uNt .ant-select-selection .ant-select-arrow svg {
  height: 12px;
}

.CategoriesModal_mainContainer__12uNt .ant-table-footer::before {
  background: #000;
}

.CategoriesModal_mainContainer__12uNt .ant-table-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  border: none;
}

.CategoriesModal_saveButton__35H0u {
  background: #f06;
}

.CategoriesModal_saveButton__35H0u:disabled,
.CategoriesModal_saveButton__35H0u:disabled:hover {
  background: #000;
}

.CategoriesModal_modalTitle__uZ-9U {
  padding-top: 10px;
}

.CategoriesModal_modalTitleContainer__1s6iU {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 0;
}

.CategoriesModal_titleSearchContainer__1zCPv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 0;
}

.CategoriesModal_searchBar__2Pqnl {
  margin-top: 10px;
  height: 29px;
}

.CategoriesModal_searchBar__2Pqnl .ant-input-prefix {
  left: 7px;
}

.CategoriesModal_titleSearchContainer__1zCPv .ant-input-affix-wrapper.CategoriesModal_searchBar__2Pqnl .ant-input-suffix {
  right: 7px;
}

.CategoriesModal_categoriesModal__2V13X {
  border: 1px solid #515152;
  background: #000;
  padding-bottom: 0;
  border-radius: 7px;
}

.CategoriesModal_categoriesModal__2V13X .ant-modal-header {
  border-color: #000;
  background: #000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  height: auto;
  padding-right: 5px;
  padding-left: 20px;
  width: 100%;
}

.CategoriesModal_categoriesModal__2V13X .ant-modal-body {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top: none;
  overflow-y: scroll;
  padding: 0;
  min-height: 0;
  border-color: #000;
}

.CategoriesModal_categoriesModal__2V13X .ant-modal-close {
  display: none;
}

.CategoriesModal_categoriesModal__2V13X .ant-modal-footer {
  background: #000;
  border-top: none;
}

.Dropdown_dropdownContainer__Opufe {
  width: 203px;
  position: relative;
}

.Dropdown_actionsContainer__3SJ2W {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  border: 1px solid #f06;
  border-radius: 6px;
  cursor: pointer;
}

.Dropdown_preselectedAction__3a35a,
.Dropdown_preselectedAction__3a35a:hover,
.Dropdown_preselectedAction__3a35a:disabled,
.Dropdown_preselectedAction__3a35a:disabled:hover {
  width: calc(100% - 30px);
  border: none;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Dropdown_preselectedAction__3a35a:disabled,
.Dropdown_preselectedAction__3a35a:disabled:hover,
.Dropdown_preselectedAction__3a35a.Dropdown_noDropdown__1a8yu:disabled,
.Dropdown_preselectedAction__3a35a.Dropdown_noDropdown__1a8yu:disabled:hover {
  background: #000;
}

.Dropdown_preselectedAction__3a35a.Dropdown_noDropdown__1a8yu,
.Dropdown_preselectedAction__3a35a.Dropdown_noDropdown__1a8yu:hover,
.Dropdown_preselectedAction__3a35a.Dropdown_noDropdown__1a8yu:disabled,
.Dropdown_preselectedAction__3a35a.Dropdown_noDropdown__1a8yu:disabled:hover {
  width: 100%;
  border: none;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.Dropdown_dropdownTrigger__EnJMY {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 30px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.Dropdown_dropdownMenu__1ybWO {
  position: absolute;
  left: 1.5px;
  z-index: 1000;
  width: 200px;
}

.Dropdown_menuVisible__2m6Fc {
  visibility: visible;
}

.Dropdown_menuHidden__3YXyX {
  visibility: hidden;
}

.FinalListPage_pageTop__IdvKu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  margin: 0;
}

.FinalListPage_headings__1qTin {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
}

.FinalListPage_header__2lBpE {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  font-size: 20px;
  font-weight: 200;
}

.FinalListPage_subHeader__2zi_a {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

.FinalListPage_platformToggle__1NQdM {
  margin-bottom: 15px;
}

.FinalListPage_dashboardContainer__3MRXr {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center;
  background-color: #15181c;
  border-radius: 5px;
  padding: 10px 15px 5px 15px;
  margin-bottom: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 90%;
}

.FinalListPage_userConsole__3f_Ia {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  box-sizing: border-box;
  background-color: #15181c;
  border-radius: 3px;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  margin-right: 25px;
}

.FinalListPage_checkmarksNoOfYes__2rddX {
  width: 110px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  text-align: left;
  color: green;
  height: 70px;
}

.FinalListPage_checkmarksDecisions__1IztW {
  width: 90px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  text-align: left;
  height: 70px;
}

.FinalListPage_decisionsTable__2FBsY {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  font-size: 14px;
  text-align: left;
  padding-bottom: 4px;
}

.FinalListPage_checkmarksDecisions__1IztW span,
.FinalListPage_checkmarksNoOfYes__2rddX span {
  font-size: 13px;
  margin-top: 1px;
  margin-bottom: 2px;
}

.FinalListPage_decisionsTable__2FBsY p {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 300;
}

.FinalListPage_headerTableContainer__2_r6e {
  padding: 0;
  box-sizing: border-box;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
}

.FinalListPage_addButtonActions__HhsYI {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-align-items: center;
          align-items: center;
}

.FinalListPage_addButtonContainer__28woF {
  height: 55px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding-bottom: 10px;
}

.FinalListPage_accountsCount__3TReZ {
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 0.3px;
}

.FinalListPage_finalCall__BGuqH .anticon svg {
  height: 10px;
  margin: 0;
}

.FinalListPage_loading__1vCeu * {
  color: #fff;
}

.FinalListPage_loading__1vCeu i {
  font-size: 50px;
  padding-bottom: 20px;
}

.FinalListPage_yesVotes__5_1To {
  color: rgb(1, 255, 1);
}

.FinalListPage_noVotes__1-qKK {
  color: rgb(255, 0, 0);
}

.FinalListPage_bigPinkButton__2s4Eb,
.FinalListPage_bigPinkButton__2s4Eb:focus {
  margin-right: 25px;
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  padding-top: 12px;
}

.FinalListPage_iconButtonsContainer__3jptL {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  margin-bottom: 5.5px;
  margin-right: 10px;
  max-height: 100px;
}

.FinalListPage_csvFollowerContainer__3vSj4 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 5px;
}

.FinalListPage_downloadIcon__2Oxwf {
  width: 28px;
}

.FinalListPage_refreshIcon__3ycE2 {
  width: 21px;
  height: 21px;
  margin-top: 3px;
  margin-bottom: 9px;
}

.FinalListPage_filtersHeader__Jo2Lm {
  width: 90%;
  line-height: 15px;
  font-weight: 500;
  letter-spacing: 0.4px;
  font-size: 13px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  padding-bottom: 3px;
  margin-bottom: 0;
}

.FinalListPage_detailsBtn__MSVjR {
  height: 29px;
  width: 30px;
  border: none;
  background-image: url(/static/media/hiring-white.26501108.svg);
  background-repeat: no-repeat;
  background-size: 25px 29px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 5px;
}

.FinalListPage_detailsBtn__MSVjR:hover,
.FinalListPage_detailsBtn__MSVjR:focus,
.FinalListPage_detailsBtn__MSVjR:active {
  background-repeat: no-repeat;
  background-size: 25px 29px;
  background-color: #15181c;
  background-image: url(/static/media/hiring-white.26501108.svg);
  border: none;
}

.FinalListPage_radioBtnContainer__2kUnF {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  width: 130px;
  margin-left: 30px;
}

.FinalListPage_radioField__xojw9 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
          flex-flow: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 60px;
  font-size: 13px;
}

.FinalListPage_radioButton__1tEKX {
  display: block;
  margin-bottom: 3px;
}

.FinalListPage_radioContainer__20DOK {
  position: relative;
}

.FinalListPage_radioPoint__2imG- {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f06;
  top: 6px;
  right: 12.5px;
}

.FinalListPage_radioBtnContainer__2kUnF .FinalListPage_filtersHeader__Jo2Lm {
  margin-bottom: 5px;
  line-height: 15px;
}

.FinalListPage_radioButton__1tEKX .ant-radio-inner {
  border-color: rgba(255, 255, 255, 0.5);
  width: 17px;
  height: 17px;
}

.FinalListPage_radioButton__1tEKX .ant-radio-checked .ant-radio-inner {
  border-color: #f06;
}

.FinalListPage_radioButton__1tEKX .ant-radio-checked .ant-radio-inner::after {
  display: none;
}

.FinalListPage_checkboxContainer__29LGn .FinalListPage_filtersHeader__Jo2Lm:last-of-type {
  margin-bottom: 3px;
}

.FinalListPage_notification__38r54 {
  border: 1px solid rgba(249, 40, 15, 1);
  background: rgba(249, 40, 15, 0.8);
  margin-top: 30px;
  margin-left: 80px;
}

.FinalListPage_notification__38r54 .ant-notification-notice-close * {
  color: #fff;
}

.AuthLayout_container__3qqak {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  background: #000;
}
.AuthLayout_comonaut__22l10 {
  background-image: url(/static/media/COMONAUT.7456c6dc.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 40%;
  left: 69.5%;
}
.AuthLayout_subContainer__W6aPf {
  height: 50%;
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
.AuthLayout_title__3j0bq {
  font-family: 'HelveticaNeue-CondensedBlack', sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: center;
          justify-content: center;
  color: #fff;
  font-size: 38px;
  font-weight: 600;
  position: relative;
  margin: 50px 0 70px 0;
  letter-spacing: 0.4px;
}
.AuthLayout_logo__27bWO {
  display: block;
  width: 65px;
  height: auto;
}
.AuthLayout_contentFooterContainer__3yUY6 {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.AuthLayout_googleLogin__zksOd {
  background: none;
  border: none;
  cursor: pointer;
}
.AuthLayout_googleLogin__zksOd:focus,
.AuthLayout_googleLogin__zksOd:hover,
.AuthLayout_googleLogin__zksOd:active {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}
.AuthLayout_title__3j0bq span:first-of-type {
  margin-right: 15px;
}

.Login_formContainer__17x6c {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  font-size: 20px;
}
.Login_input__35vwd {
  margin-top: 15px;
}

.Login_LoginFormButton__2zltx {
  width: 164px;
  height: 48px;
  border-radius: 12px;
  margin-top: 25px;
  font-size: 18px;
}
.Login_input__35vwd i svg {
  width: 30px;
  height: 30px;
}
.Login_input__35vwd input::-webkit-input-placeholder {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.8);
}
.Login_input__35vwd input::-ms-input-placeholder {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.8);
}
.Login_input__35vwd input::placeholder {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.8);
}
.ant-input-affix-wrapper.Login_input__35vwd input.ant-input {
  padding-left: 50px;
  font-size: 18px;
  max-width: 265px;
  min-width: 150px;
  height: 48px;
  border-radius: 12px;
  border: 1px solid #707070;
  background-color: #a3a3a3;
}
.Login_alert__3V8FR {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background: #000;
  color: red;
  border: none;
  border-radius: 12px;

}

.ErrorOverlay_mainContainer__3zFqn {
  display: -webkit-flex;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1300;
}
.ErrorOverlay_overlayContainer__uED1q {
  display: -webkit-flex;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1400;
}
.ErrorOverlay_errorAlert__2sII0 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  top: 34%;
  left: 37%;
  z-index: 1500;
}
.ErrorOverlay_errorMessage__1d8c_ {
  padding: 10px 30px 0 20px;
  margin-bottom: 10px;
}
.ErrorOverlay_errorMessageText__168ZS {
  font-size: 15px;
  letter-spacing: 0.2px;
}
.ErrorOverlay_message__1lNqy {
  font-size: 17px;
  text-decoration: underline;
}
.ErrorOverlay_errorMessage__1d8c_ pre {
  padding-top: 10px;
}
.ErrorOverlay_reloadButton__33uI1 {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 17px;
}
.ErrorOverlay_email__3qbCO {
  margin-top: 15px;
  color: #60C0F9;
  font-weight: 600;
  font-size: 17px;
  text-decoration: underline;
}
.ErrorOverlay_email__3qbCO:hover {
  color: #60C0F9;
}
.ant-alert.ant-alert-error.ant-alert-no-icon.ErrorOverlay_errorAlert__2sII0 {
  position: absolute;
  top: 30%;
  left: 30%;
  z-index: 1500;
  margin-bottom: 20px;
}

.BlankCenteredLayout_container__93scS {
  position: fixed;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  width: 100vw;
  color: #000;
}

.OfflineOverlay_container__uiCZW {
  position: fixed;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.OfflineOverlay_main__1LmXp,
.OfflineOverlay_main__1LmXp h1 {
  text-align: center;
  color: #fff;
  padding: 20px 0 40px 0;
  width: 500px;
}

