.searchBar {
  height: 40px;
  width: 17%;
  min-width: 200px;
}
.iconSize svg {
  width: 20px;
  height: 20px;
  color: rgba(255, 255, 255, 0.5);
}
.searchBar input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
  letter-spacing: 0.15px;
}
:global(.ant-input-affix-wrapper).searchBar input:global(.ant-input) {
  padding-left: 40px;
  font-size: 18px;
  border: 1px solid #f06;
  background-color: #000;
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 100;
  letter-spacing: 0.15px;
}
:global(.ant-input-affix-wrapper).searchBar input:global(.ant-input):focus,
:global(.ant-input-affix-wrapper).searchBar input:global(.ant-input):active,
:global(.ant-input-affix-wrapper).searchBar input:global(.ant-input):hover {
  border: 1px solid rgba(255, 255, 255, 0.5);
}
