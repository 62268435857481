.closeIcon {
  color: #fff;
  width: 40px;
  height: 40px;
}

:global(.ant-modal).container {
  min-width: 520px;
}

.container :global(.ant-modal-body),
.container :global(.ant-modal-header) {
  border: 1px solid #858585;
}

.container :global(.ant-modal-content) {
  border: none;
}

.container :global(.ant-modal-header) {
  background: #515152;
  border-bottom: none;
  height: auto;
}

.container :global(.ant-modal-body) {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top: none;
  max-height: 450px;
  min-height: 450px;
  overflow-y: scroll;
  padding: 0;
}

.container :global(.ant-modal-header .ant-modal-title) {
  font-size: 20px;
}
