.categoryTagBox {
  width: calc(100% - 4.5px);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  height: 20px;
  border: 1px solid #f06;
  border-radius: 3px;
  margin-right: 4.5px;
  margin-top: 1.5px;
  margin-bottom: 1.5px;
  background: rgba(100, 0, 40, 0.5);
}

.categoryTag {
  width: auto;
  height: 20px;
  max-width: 95%;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(255, 255, 255, 0.8);
  padding: 0 5px;
}
