.fullscreen {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
}

.container {
  height: 100%;
  width: 100%;
}

.message {
  text-align: center;
}
