.header {
  display: inline-block;
  background: linear-gradient(to bottom, #000 0%, #000 78%, #f06 78%, #f06 100%);
  color: #fff;
}
.small {
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.8);
  background: linear-gradient(to bottom, #15181c 0%, #15181c 78%, #f06 78%, #f06 100%);
  font-weight: 400;
  margin: 10px 0;
}
.medium {
  font-size: 15px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.8);
  background: linear-gradient(to bottom, #15181c 0%, #15181c 78%, #f06 78%, #f06 100%);
  font-weight: 400;
  margin: 10px 0;
}
.dashboard {
  font-size: 35px;
  line-height: 38px;
  background: linear-gradient(to bottom, #000 0%, #000 75%, #f06 75%, #f06 100%);
  font-weight: 600;
  text-transform: uppercase;
  margin: 10px 0;
}
.big {
  height: 48px;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 30px;
}

.phone {
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  background: linear-gradient(to bottom, #15181c 0%, #15181c 78%, #f06 78%, #f06 100%);
  font-weight: 600;
  margin: 0;
  margin-bottom: 3px;
  margin-right: 10px;
  align-items: flex-start;
}
